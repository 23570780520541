import styled from 'styled-components';

export const GameContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 400px;
  height: 100vh;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 35px;
  color: white;
  flex-grow: 1;
  text-align: center;
  margin-left: 25px;
`;

export const Text = styled.div`
  width: 100%;
  font-size: 20px;
  color: white;
  text-align: center;
  margin-bottom: 10px;
`;

export const TopContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
`;
