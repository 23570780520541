import styled from 'styled-components';
export const StyledImageContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 13px;
  border-radius: 20px;
`

export const HistoryContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const GuessItem = styled.div`
  min-height: 28px;
  flex-grow: 4;
  background: ${({background}) => background};
  ${({isLatest}) => !isLatest && 'margin-bottom: 10px;'};
  border-radius: 7px;
  display: flex;
  ${({isLatest}) => isLatest && 'border: 2px solid white;'};
`;

export const PromptText = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 10px;
  color: rgb(255, 255, 255);
  font-weight: 300;
  line-height: 20px;
`;

export const SolutionText = styled.div`
  display: flex;
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
`;
export const PromptScore = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  margin-left: auto;
  color: rgb(255, 255, 255);
  padding-left: 10px;
`;

export const CounterText = styled.div`
  color: white;
  text-align: center;
  align-self: center;
  flex-grow: 1;
  margin-left: 15px;
`;

export const ConCon = styled.div`
display: flex;
margin-bottom: 20px;
`;
