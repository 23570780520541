
export const setGuessHistory = (challengeId, guessHistory) => {
    localStorage.setItem(`${challengeId}.guessHistory`, JSON.stringify(guessHistory));
}

export const getGuessHistory = (challengeId) => {
    const cachedHistory = localStorage.getItem(`${challengeId}.guessHistory`);
    if (cachedHistory !== 'undefined') {
        return JSON.parse(cachedHistory);
    }
    return null;
}
