import React from 'react';
import { Modal } from 'antd';

export const POPUP_NAMES = {
    HOW_TO_PLAY: 'howToPlay'
}
const PopupContainer = ({ displayedPopupName, onDismiss }) => {
    return (
        <Modal open={displayedPopupName === POPUP_NAMES.HOW_TO_PLAY} title={"How to play"} onOk={onDismiss} closable={false}
               cancelButtonProps={{ style: { display: 'none' }}}>
            <p>
            Below is an image generated from a prompt using AI. Can you find out what the prompt is? Every guess has a score between 0 to 100.
            The solution prompt is up to 100 characters
            </p>
        </Modal>
    )
};
export default PopupContainer;
