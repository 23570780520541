import React, { useState, useEffect } from 'react';
import { Input, Image, Carousel } from 'antd';
import { getChallengeById, sendGuess } from "../services/network";
import { StyledImageContainer, HistoryContainer, SolutionText, CounterText, ConCon } from './styled';
import GuessHistoryItem from './guess-history-item';
import * as storageService from '../services/storage';
const ChallengeContainer = ({ challenge }) => {
    const [guessHistory, setGuessHistory] = useState([]);
    const [guessInput, setGuessInput] = useState(null);
    const [isGameOver, setIsGameOver] = useState(false);
    const [solutionPrompt, setSolutionPrompt] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const historyOrderedByScore = [...guessHistory].sort((a, b) => b.score - a.score);
    useEffect(() => {
            const cachedHistory = storageService.getGuessHistory(challenge.id);
            if (cachedHistory) {
                setGuessHistory(cachedHistory);
            }
    }, []);

    useEffect(() => {
        if (historyOrderedByScore.length && historyOrderedByScore[0].isCorrect && !isGameOver) {
            setIsGameOver(true);
        }
    }, [guessHistory]);

    const fetchSolutionPrompt = async () => {
        const { prompt } = await getChallengeById(challenge.id);
        setSolutionPrompt(prompt);
    }

    useEffect(() => {
        if (isGameOver && !solutionPrompt) {
            fetchSolutionPrompt();
        }
    }, [isGameOver]);

    const updateGuessHistory = (guess) => {
        const newGuessHistory = [...guessHistory, guess];
        setGuessHistory(newGuessHistory);
        storageService.setGuessHistory(challenge.id, newGuessHistory);
    }
    const handleSubmitGuess = async () => {
        setIsLoading(true);
        try {
            const {isCorrect, score, prompt} = await sendGuess(challenge.id, guessInput);
            updateGuessHistory({challengeId: challenge.id, prompt: guessInput, score, isCorrect});
            if (isCorrect) {
                setIsGameOver(isCorrect);
                setSolutionPrompt(prompt)
            }
        } catch (e) {
            console.log('error', e.message)
        }
        setIsLoading(false);
    }

    return (
        <>
            <StyledImageContainer>
                <Carousel>
                    <div>
                        <Image src={challenge && challenge.imageUrls[0]}
                               style={{ borderRadius: '10px' }}/>
                    </div>
                    <div>
                        <Image src={challenge && challenge.imageUrls[1]}
                               style={{ borderRadius: '10px' }}/>
                    </div>
                    <div>
                        <Image src={challenge && challenge.imageUrls[2]}
                               style={{ borderRadius: '10px' }}/>
                    </div>
                    <div>
                        <Image src={challenge && challenge.imageUrls[3]}
                               style={{ borderRadius: '10px' }}/>
                    </div>
                </Carousel>
            </StyledImageContainer>
            {!isGameOver && <Input.Search
                placeholder="Guess the prompt"
                onChange={(e) => setGuessInput(e.target.value)}
                onSearch={handleSubmitGuess}
                enterButton="Submit"
                loading={isLoading}
            />}
            <HistoryContainer>
            {guessHistory[guessHistory.length-1] && (
                <>
                {isGameOver ? <div style={{ marginBottom: '20px' }}><SolutionText>{guessHistory.length} {guessHistory.length === 1 ? 'guess' : 'guesses'}</SolutionText>
                    <GuessHistoryItem guess={{...guessHistory[guessHistory.length-1], prompt: solutionPrompt, score: 1 }} isLatest />
                </div> :
                    <ConCon>
                        <GuessHistoryItem guess={guessHistory[guessHistory.length-1]} isLatest />
                        <CounterText>{guessHistory.length}</CounterText>
                    </ConCon>
                    }
            </>)}
            {historyOrderedByScore.map(g => <>
                <GuessHistoryItem guess={g} />
                <div style={{marginBottom: '10px'}} />
                </>)}
            </HistoryContainer>
        </>
    )
}

export default ChallengeContainer;
