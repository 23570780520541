import axios from 'axios';
import config from '../config';
import { getOrCreateUser } from './user';

const networkService = axios.create({
    baseURL: config.BACKEND_URL,
})

const getRequestHeaders = () => ({
    'user-id': getOrCreateUser(),
});

export const getDailyChallenge = async () => {
    const { data } = await networkService.get('/api/v1/challenges/daily', {
        headers: getRequestHeaders()
    });
    return data;
}

export const sendGuess = async (challengeId, guess) => {
    const { data } = await networkService.post(`/api/v1/challenges/${challengeId}/guess`, { guess }, {
        headers: getRequestHeaders()
    });
    return data;
}

export const getChallengeById = async (challengeId) => {
    const { data } = await networkService.get(`/api/v1/challenges/${challengeId}`, {
        headers: getRequestHeaders()
    });
    return data;
}
