import React from 'react';
import { theme, ConfigProvider } from 'antd';
import DailyChallenge from './DailyChallenge';
import { AppContainer } from './styled';
const App = () => (
    <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
    <AppContainer>
    <DailyChallenge/>
    </AppContainer>
    </ConfigProvider>
  );

export default App;
