import React from 'react';
import { Dropdown } from "antd";
import { EllipsisOutlined, MailOutlined, QuestionCircleOutlined, BulbOutlined } from "@ant-design/icons";

const MENU_STYLES = { fontSize: '18px' }
const makeMenuItems = ({ onPressHowToPlay }) => [
    {
        key: '0',
        label: (<a style={MENU_STYLES} onClick={onPressHowToPlay}>how to play</a>),
        icon: <QuestionCircleOutlined style={MENU_STYLES}/>
    },
    {
        key: '1',
        label: (<a style={MENU_STYLES}>hint</a>),
        icon: <BulbOutlined style={MENU_STYLES}/>,
        disabled: true
    },
    {
        key: '2',
        label: (<a style={MENU_STYLES} href="mailto:ofekch221@gmail.com">contact</a>),
        icon: <MailOutlined style={MENU_STYLES}/>
    },
]

const TopMenuContainer = ({onPressHowToPlay}) => {
    const menuItems = makeMenuItems({ onPressHowToPlay });
    return (
        <Dropdown trigger={['click']} menu={{ items: menuItems, style: { backgroundColor: '#333333' } }} placement={'bottomRight'}>
        <EllipsisOutlined style={{ color: 'white',  fontSize: '25px', display: 'flex' }} rotate={90} />
    </Dropdown>)
}

export default TopMenuContainer
