import React, { useEffect, useState } from 'react';
import { Skeleton, Image } from 'antd';
import { getDailyChallenge } from "../services/network";
import ChallengeContainer from "../ChallengeContainer";
import { GameContainer, Title, TopContainer, Text } from './styled';
import { StyledImageContainer } from "../ChallengeContainer/styled";
import TopMenuContainer from "../TopMenuContainer";
import PopupContainer, { POPUP_NAMES } from "../PopupContainer";
import { getUser } from "../services/user";
import AppStoreImage from '../app-store.png';
import GooglePlayImage from '../google-play.png';

const DailyChallenge = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [challenge, setChallenge] = useState(null);
    const [displayedPopupName, setDisplayedPopupName] = useState(null);
    const fetchChallenge = async () => {
        const fetchedChallenge = await getDailyChallenge();
        setChallenge(fetchedChallenge);
    }
    useEffect(() => {
        if (challenge) {
            setIsLoading(false);
        }
    }, [challenge]);

    useEffect(() => {
        const isNewUser = !getUser();
        if (isNewUser) {
            setDisplayedPopupName(POPUP_NAMES.HOW_TO_PLAY)
        }
        fetchChallenge()
    }, []);

    return (
            <GameContainer>
                <TopContainer>
                <Title>Prompter</Title>
                <TopMenuContainer onPressHowToPlay={() => setDisplayedPopupName(POPUP_NAMES.HOW_TO_PLAY)} />
                </TopContainer>
                <Text>
                    Download the free mobile app to access 50+ challenges, hints, and more!
                </Text>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                    <a href={'https://apps.apple.com/app/prompter-guess-the-prompt/id6461601276'} ><img src={AppStoreImage} style={{ height: '45px', cursor: 'pointer'}} /></a>
                    <a href={'https://play.google.com/store/apps/details?id=com.anonymous.prompterapp'} ><img src={GooglePlayImage} style={{height: '45px'}} /></a>
                </div>
                {isLoading
                    ? <>
                        <StyledImageContainer>
                    <Skeleton.Input
                        active
                        block
                        style={{ paddingBottom: '100%' }} />
                        </StyledImageContainer>
                    <Skeleton.Input active block />
                    </>
                    :<ChallengeContainer challenge={challenge}/>}
                <PopupContainer displayedPopupName={displayedPopupName} onDismiss={() => setDisplayedPopupName(null)}/>
            </GameContainer>
    )
}

export default DailyChallenge;
