import { v4 as uuid4 } from 'uuid';
export const getOrCreateUser = () => {
    let userId = localStorage.getItem(`userId`);
    if (!userId) {
        userId = uuid4();
        localStorage.setItem(`userId`, userId);
    }
    return userId;
}

export const getUser = () => {
    return localStorage.getItem(`userId`);
}
