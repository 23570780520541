import React from 'react';
import { GuessItem, PromptText, PromptScore } from './styled';

const colorMap = {
    0: '#ff2b38',
    1: '#e59200',
    2: '#00a342',
    3: 'rgb(0 208 84)',
}

const GuessHistoryItem = ({ guess, isLatest }) => {
    const formattedScore = Math.max(guess.score * 100, 0);
    return (
        <GuessItem isLatest={isLatest} background={colorMap[Math.round(formattedScore/30)]}>
            <PromptText>{guess.prompt}</PromptText>
            <PromptScore>{formattedScore.toFixed(2)}</PromptScore>
        </GuessItem>
    )
}
export default GuessHistoryItem;
